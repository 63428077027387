<template>
  <v-card style="position: relative; height: 420px">
    <v-card class="elevation-0">
      <v-card-title class="headline display-1">
        <v-row class="d-flex align-center ml-4 mt-n4">
          Bilan des interventions par
          <v-col cols="3">
            <v-select
              class="title"
              :items="types"
              v-model="selectedType"
              hide-details
              solo
            ></v-select>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <bar-chart
          :chart-data="collection"
          :height="300"
          :width="null"
        ></bar-chart>
      </v-card-text>
    </v-card>
  </v-card>
</template>
<script>
import _groupby from "lodash.groupby";
import { DefaultChartProps } from "../../mixins/default-chart-props";
import BarChart from "./BarChart.js";
export default {
  name: "DefaultChart",
  components: { BarChart },
  props: {
    statusList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedType: null
    };
  },
  mixins: [DefaultChartProps],
  computed: {
    types() {
      return [
        {
          value: "name",
          text: "périmètre"
        },
        {
          value: "equipment",
          text: this.equipLabel.toLowerCase()
        }
      ];
    },
    collection() {
      let data = {};
      const filteredTickets = this.tickets.filter(
        x => x[this.selectedType] !== undefined && x.status !== 6
      );
      data.labels = Object.keys(_groupby(filteredTickets, this.selectedType));
      data.datasets = [];
      this.statusList.forEach(status => {
        data.datasets.push({
          label: status.name,
          data: data.labels.map(ticket => {
            return filteredTickets.filter(
              tkt =>
                tkt[this.selectedType] == ticket && tkt.status === status.status
            ).length;
          }),
          backgroundColor: status.color
        });
      });
      return data;
    },
    currentType() {
      return this.types.find(type => type.value === this.selectedType);
    }
  },
  methods: {
    getTypeName(type) {
      let states = this.getActiveEntity.state;
      states = states ? JSON.parse(states) : states;
      let typeName = states && states[type] ? states[type] : null;
      if (type === "location" && typeName) {
        return "Emplacement";
      }
      return typeName ? typeName : type;
    }
  },
  mounted() {
    this.selectedType = this.searchKey;
  }
};
</script>
<style scoped>
.chart_ {
  height: 400px !important;
}
</style>
