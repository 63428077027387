import { HorizontalBar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: HorizontalBar,
  mixins: [reactiveProp],
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              stacked: true,
              ticks: {
                stepSize: 1
              }
            }
          ],
          yAxes: [{ stacked: true }]
        },
        layout: {
          padding: {
            right: 0,
            left: 0,
            top: 0,
            bottom: 0 //set that fits the best
          }
        },
        legend: {
          display: true,
          position: "bottom",
          align: "end"
        }
      }
    };
  }
};
