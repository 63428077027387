export const DefaultChartProps = {
  props: {
    title: {
      type: String,
      default: "Exploitation"
    },
    tickets: {
      type: Array,
      default() {
        return [];
      }
    },
    loaded: {
      type: Boolean,
      default: false
    },
    searchKey: {
      type: String,
      default: "name"
    },
    equipLabel: {
      type: String,
      default: "Alimentation"
    }
  }
};
