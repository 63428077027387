<template>
  <v-col
    v-if="displayedTicketChart"
    cols="12"
    class="pb-0 pt-0"
    style="position: relative; height: 380px"
  >
    <!--
    <v-card>
      <v-card-text>
        <v-list>
          <v-list-item>
            <v-list-item-content class="pa-0">
              <v-row style="height: 320px;">
                <v-col
                  cols="12"
                  class="d-flex justify-center align-center fill-height pa-0"
                  style="width: 100%;"
                >
                  <iframe
                    :src="src_iframe"
                    width="98%"
                    height="100%"
                    id="menu-frame"
                    sandbox="allow-scripts allow-same-origin allow-top-navigation"
                    allowTransparency="true"
                    scrolling="no"
                    frameborder="0"
                  ></iframe>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>-->
  </v-col>
  <v-col
    v-else
    cols="12"
    class="pb-0 pt-0"
    style="position: relative; height: 780px"
  >
    <!--
    <v-card>
      <v-card-text>
        <v-list>
          <v-list-item>
            <v-list-item-content class="pa-0">
              <v-row style="height: 720px;">
                <v-col
                  cols="12"
                  class="d-flex justify-center align-center fill-height pa-0"
                  style="width: 100%;"
                >
                  <iframe
                    :src="src_iframe"
                    width="98%"
                    height="100%"
                    id="menu-frame"
                    sandbox="allow-scripts allow-same-origin allow-top-navigation"
                    allowTransparency="true"
                    scrolling="no"
                    frameborder="0"
                  ></iframe>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>-->
  </v-col>
</template>

<script>
import { LayoutOptions } from "../../mixins/layouts";
import { mapGetters } from "vuex";
import { PORTAL_CONFIG } from "../../config";

export default {
  name: "perf",
  data() {
    return {
      loadTime: 0
    };
  },
  computed: {
    ...mapGetters(["getEcranModule"]),
    src_iframe() {
      return (
        PORTAL_CONFIG.SERVER_WEB +
        "/" +
        PORTAL_CONFIG.APP_NAME +
        "/" +
        PORTAL_CONFIG.CLIENT_DATA +
        "/perfs.php?entity=" +
        this.params.id
      );
    },
    params() {
      return {
        id: this.$route.params.id,
        userToken: this.$route.params.userToken
      };
    },
    displayedTicketChart() {
      if (!this.getEcranModule) return true;
      return this.getEcranModule.module2 != 0;
    }
  },
  mixins: [LayoutOptions]
};
</script>

<style scoped></style>
